import ArrowDownIcon from "./arrow-down";
import * as SelectPrimitive from "@radix-ui/react-select";
import React from "react";

export const Select = React.forwardRef<
  HTMLButtonElement,
  SelectPrimitive.SelectProps &
    SelectPrimitive.SelectValueProps & {
      triggerClassName?: string;
      iconFill?: string;
      contentClassName?: string;
      contentStyle?: React.CSSProperties;
    }
>(
  (
    {
      children,
      placeholder,
      triggerClassName,
      contentClassName,
      contentStyle,
      iconFill,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <SelectPrimitive.Root {...props}>
        <SelectPrimitive.Trigger
          ref={forwardedRef}
          className={triggerClassName}
        >
          <SelectPrimitive.Value
            className="filter-btn"
            placeholder={placeholder}
          />
          <SelectPrimitive.Icon>
            <ArrowDownIcon iconFill={iconFill} />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={contentClassName}
            style={contentStyle}
          >
            <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    );
  }
);
Select.displayName = "Select";

export const SelectItem = React.forwardRef<
  HTMLDivElement,
  SelectPrimitive.SelectItemProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <SelectPrimitive.Item {...props} ref={forwardedRef}>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = "SelectItem";
