[
  {
    "language": "en",
    "messages": {
      "hello": "Hello {name}!",
      "log-in-title": "Enter your credentials to log in",
      "log-in-data-not-correct": "Invalid username or password",
      "log-in-forgot": "Forgot your password?",
      "log-in": "Log in",
      "log-in-code-error": "Enter the code again.",
      "log-in-code-label": "Enter the generated TOTP code into your authenticator app",
      "mfa-intro": "To complete the Log in process it is necessary to activate multi-factor authentication. You can use two alternative modes:",
      "mfa-list-intro": "<ul><li>Scan the «QR CODE»</li><li>Or click on «Detect code» and enter it into your authentication app</li></ul><div>Finally, enter the code generated by the app below. If you need support, contact <a href=\"mailto:login@coreconsulting.it\">login@coreconsulting.it</a></div>",
      "code-reveal": "DETECT CODE",
      "code-label": "Enter the code generated by the app here",
      "code-not-valid": "The code entered is invalid.",
      "confirm": "Confirm",
      "new-pswrd-title": "Choose your new password",
      "pswrd-not-match": "The passwords don't match",
      "password-char": "The password must contain at least {length} characters",
      "password-numbers": ", a number",
      "password-uppercase": ", a capital letter",
      "password-special": ", a special character",
      "onboarding": "Onboarding",
      "reset-username": "Enter your username",
      "reset-password": "Reset password",
      "send": "Send",
      "submission-error": "Request unsuccessful, try again",
      "verify-code-send": "If the username you entered is valid, you will receive a verification code to your email address. If you have not received the email, check your spam or contact <a href=\"mailto:login@coreconsulting.it\">login@coreconsulting.it</a>",
      "insert-code": "Enter the code here",
      "insert-code-again": "Enter the code again",
      "choose-password": "Choose your password",
      "copy-code-success": "Code copied to clipboard",
      "mfa": "Multi-factor authentication",
      "confirm-password": "Confirm Password",
      "username": "Username",
      "back": "Back"
    }
  },
  {
    "language": "it",
    "messages": {
      "reset-password": "Reimposta password",
      "hello": "Bonjour {name}!",
      "log-in-title": "Inserisci le tue credenziali per accedere",
      "log-in-data-not-correct": "Le credenziali non sono corrette",
      "log-in-forgot": "Hai dimenticato la password?",
      "log-in": "Accedi",
      "log-in-code-error": "Inserisci nuovamente il codice",
      "log-in-code-label": "Inserisci il codice TOTP generato nella tua app di autenticazione",
      "mfa-intro": "Per concludere il processo di Log in è necessario attivare l'autenticazione multi fattore. Puoi utilizzare due modalità alternative:",
      "mfa-list-intro": "<ul><li>Scannerizza il «QR CODE»</li><li>Oppure clicca su «Rileva il codice» e inseriscilo nella tua app di autenticazione</li></ul><div>Infine, inserisci qui sotto il codice generato dall'app. Se hai bisogno di supporto, contatta <a href=\"mailto:login@coreconsulting.it\">login@coreconsulting.it</a></div>",
      "code-reveal": "RILEVA IL CODICE",
      "code-label": "Dopo di che, inserisci qui il codice generato",
      "code-not-valid": "Il codice inserito non è valido",
      "confirm": "Conferma",
      "new-pswrd-title": "Scegli la tua nuova password",
      "pswrd-not-match": "Le password non coincidono",
      "password-char": "La password deve contenere almeno {length} caratteri",
      "password-numbers": ", almeno un numero",
      "password-uppercase": ", una lettera maiuscola",
      "password-special": ", un carattere speciale",
      "onboarding": "Onboarding",
      "reset-username": "Inserisci il tuo nome utente",
      "send": "Invia",
      "submission-error": " Non è stato possibile eseguire la richiesta, riprova",
      "verify-code-send": "Se il nome utente inserito è valido, riceverai un codice di verifica al tuo indirizzo e-mail. Se non hai ricevuto l'e-mail, verifica nello spam oppure contatta <a href=\"mailto:login@coreconsulting.it\">login@coreconsulting.it</a>",
      "insert-code": "Inserisci qui il codice",
      "insert-code-again": "Inserisci nuovamente il codice",
      "choose-password": "Scegli la tua password",
      "mfa": "Autenticazione multi fattore",
      "confirm-password": "Conferma Password",
      "username": "Nome utente",
      "back": "Indietro"
    }
  }
]
