import CoreLogo from "./core-logo";
import Header from "./header";
import { Select, SelectItem } from "./select";
import { TranslationsProvider } from "@eo-locale/react";
import { useRouteLoaderData } from "@remix-run/react";
import Cookies from "js-cookie";
import * as React from "react";
import locales from "~/locales/languages.json";
import { LocaleProvider, languages } from "~/locales/use-locale";

type LayoutProps = {
  children: React.ReactNode;
  color?: string;
  secondaryColor?: string;
};

export default function Layout({
  children,
  color,
  secondaryColor,
}: LayoutProps) {
  const { language } = useRouteLoaderData("root");

  const [languageSelector, setLanguageSelector] =
    React.useState<string>(language);

  function onFilterChange(value: string) {
    setLanguageSelector(value);
  }

  return (
    <LocaleProvider locale={languageSelector}>
      <TranslationsProvider language={languageSelector} locales={locales}>
        <div
          style={
            {
              "--primary-color": color,
              "--secondary-color": secondaryColor,
            } as React.CSSProperties
          }
        >
          <Header
            className="basic-header"
            leftComponent={<div className="left"></div>}
            centerComponent={
              <div className="core-logo-container">
                <CoreLogo />
              </div>
            }
            rightComponent={
              <div className="right">
                <LanguageSelector
                  onChange={(val: string) => onFilterChange(val)}
                  value={languageSelector}
                  values={languages}
                  color={color}
                />
              </div>
            }
          ></Header>

          <main>{children}</main>
        </div>
      </TranslationsProvider>
    </LocaleProvider>
  );
}

type FilterProps = {
  onChange: (value: string) => void;

  value: string | undefined;
  values: string[];
  color?: string;
};

function LanguageSelector({ values, value, onChange, color }: FilterProps) {
  function onLanguageChange(selected: string) {
    Cookies.set("selectedLang", selected, { expires: 7 });
    onChange(selected);
  }

  return (
    <Select
      value={value}
      onValueChange={onLanguageChange}
      placeholder={"EN"}
      triggerClassName="filter-btn-header flex flex-y-center text uppercase"
      contentClassName="filter-content-header"
      iconFill={color}
      contentStyle={
        {
          marginTop: "calc(var(--header-height) - 10px",
          "--primary-color": color,
        } as React.CSSProperties
      }
    >
      {values.map((val) => (
        <SelectItem key={val} className="filter-item-header" value={val}>
          {val.slice(0, 2)}
        </SelectItem>
      ))}
    </Select>
  );
}
