import * as React from "react";

const ArrowDownIcon = ({ iconFill, ...props }: IconProps) => (
  <svg
    width={19}
    height={13}
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.315.975A.744.744 0 0 0 .643.699a.728.728 0 0 0-.584.421.678.678 0 0 0 .087.697l7.99 10.223a1.73 1.73 0 0 0 1.364.654 1.73 1.73 0 0 0 1.364-.654l7.99-10.223a.68.68 0 0 0-.146-.982.754.754 0 0 0-1.023.14l-7.99 10.223a.247.247 0 0 1-.195.094.247.247 0 0 1-.195-.094L1.315.975Z"
      fill={iconFill ? iconFill : "#131313"}
    />
  </svg>
);

export default ArrowDownIcon;
